.catList {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background: rgba(255, 255, 255, 0.95);
  padding: calc(var(--spacingUnit) / 2) 0;
  margin-bottom: var(--spacingUnit);
  z-index: 10;
  line-height: 1.2;
}

.cat {
  display: block;
}

.catLink {
  display: block;
  padding: calc(var(--spacingUnit) / 4) 0;
  text-decoration: none;
  color: #9b9b9b;
  border: 0;
  background: none;
  text-align: left;
  font-size: inherit;
  font-weight: inherit;
}

.catLink:hover,
.catLink:focus,
.catLinkSelected {
  color: #000;
  outline: 0;
}

@media screen and (min-width: 640px) {
  .catList {
    display: flex;
    position: sticky;
    top: -1px;
    padding: 0;
    align-items: center;
    justify-content: space-between;
  }

  .cat:nth-child(1) {
    width: 2.8em;
  }
  .cat:nth-child(2) {
    width: 5.9em;
  }
  .cat:nth-child(3) {
    width: 5.9em;
  }
  .cat:nth-child(4) {
    width: 6.1em;
  }
  .cat:nth-child(5) {
    width: 8.3em;
  }

  .catLink {
    padding: calc(var(--spacingUnit) / 2) 0;
  }
}

@media screen and (min-width: 1024px) {
  .catList {
    position: sticky;
    top: 48px;
    display: block;
    font-size: 24px;
    width: 312px;
    float: left;
    border: none;
    margin: 0;
    padding: 0;
    background: none;
  }
  .cat {
    display: block;
    margin: 0;
    width: auto;
  }
  .catLink {
    padding: 0 0 2em;
  }
}
