.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.textContainer {
  max-width: 35em;
  line-height: 1.4;
  margin: auto;
}

.textContainer p:not(:last-child) {
  margin-bottom: 1em;
}

@media screen and (min-width: 1024px) {
  .textContainer {
    font-size: 24px;
  }
}
