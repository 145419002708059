.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.textContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 20px;
}

@media screen and (min-width: 1024px) {
  .textContainer {
    font-size: 24px;
  }
}
